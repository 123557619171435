export const columnsState = [
  { field: "id", headerName: "Id", width: 40 },
  { field: "nombre", headerName: "Nombre", width: 40 },
  { field: "ver", headerName: "Ver", width: 20 },
];

export const columnsSucesos2 = [
  { field: "carga_fechahora", headerName: "FECHA LLAMADO", align: "center" },
  { field: "domicilio", headerName: "DOMICILIO", width: 10, align: "left" },
  { field: "subtipo", headerName: "SUBTIPO", align: "center" },
  { field: "comuna", headerName: "COMUNA", align: "left" },
  { field: "tipoDanio", headerName: "DAÑO", width: 60, align: "left" },
  { field: "tipoIncidencia", headerName: "INCIDENCIA", align: "center" },
  { field: "estado", headerName: "ESTADO", align: "center" },
  { field: "corte_de_calle", headerName: "CORTE DE CALLE", align: "center" },
  { field: "estado_empresa", headerName: "ESTADO EMPRESA", align: "center" },
  { field: "ver", headerName: "VER", align: "left" },
];
