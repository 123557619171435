import { tiendasApi } from "../api";

export const TipoDanioApiSlice = tiendasApi.injectEndpoints({
  endpoints: (builder) => ({
    getTipoDanios: builder.mutation({
      query: (headersTemp) => {
        const { page, limit } = headersTemp;
        return {
          url: `/tipodanos?page=${page}&limit=${limit}`,
          method: "GET",
        };
      },
    }),
    getTipoDanio: builder.mutation({
      query: (id) => ({
        url: "/tipodanos/" + id,
        method: "GET",
      }),
    }),
  }),
});

export const { useGetTipoDanioMutation, useGetTipoDaniosMutation } = TipoDanioApiSlice;
