import { Container, Divider, Grid, Paper, Typography } from "@mui/material";
import { ArcElement, Chart, Legend, Tooltip } from "chart.js";
import React, { useMemo } from "react";
import { Doughnut } from "react-chartjs-2";
import {
  useGetEstadoDuplicadoEmpresaQuery,
  useGetEstadoDuplicadoQuery,
  useGetSucesosCerradosPorEmpresaNoCerradoPorComunaQuery,
  useGetSucesosCerradosPorEmpresaSinFinalizarComunaQuery,
  useGetSucesosPorIncidenciasQuery,
  useGetSucesosSinAsignarQuery,
} from "../../../store/api/endpoints/sucesosApiSlice";
import { Loading } from "../../molecule";

Chart.register(ArcElement, Tooltip, Legend);

const initialState = {
  labels: ["No hay datos"],
  datasets: [
    {
      data: [1],
      backgroundColor: ["#FFCE56"],
      hoverBackgroundColor: ["#FFCE56"],
    },
  ],
};

const Indicadores = () => {
  const { data: dataEstadoSinFinalizar, isLoading: isLoadingEstadoSinFinalizar } = useGetSucesosCerradosPorEmpresaSinFinalizarComunaQuery();
  const { data: dataEmpresa, isLoading: isLoadingEmpresa, isSuccess: isSuccessEmpresa } = useGetEstadoDuplicadoEmpresaQuery();
  const { data: dataSucesoPorIncidencias, isLoading: isLoadingSucesoPorIncidencias, isSuccess: isSuccessSucesoPorIncidencia } = useGetSucesosPorIncidenciasQuery();
  const { data: dataSinAsignar, isLoading: isLoadingSinAsignar } = useGetSucesosSinAsignarQuery();
  const { data, isLoading, isSuccess } = useGetEstadoDuplicadoQuery();

  const estadosComuna = useMemo(() => {
    if (isSuccess) {
      return formatChartData(data?.data?.estados || []);
    }
    return initialState;
  }, [data, isSuccess]);

  const sucesoPorIncidencias = useMemo(() => {
    if (isSuccessSucesoPorIncidencia) {
      return formatChartData(dataSucesoPorIncidencias?.data?.incidencias || []);
    }
    return initialState;
  }, [dataSucesoPorIncidencias, isSuccessSucesoPorIncidencia]);

  const estadosEmpresa = useMemo(() => {
    if (isSuccessEmpresa) {
      return formatChartData(dataEmpresa?.data?.estadosEmpresa || []);
    }
    return initialState;
  }, [dataEmpresa, isSuccessEmpresa]);

  const globalLoading = isLoading || isLoadingEmpresa || isLoadingSinAsignar || isLoadingEstadoSinFinalizar || isLoadingSucesoPorIncidencias;

  if (globalLoading) return <Loading />;

  return (
    <Container maxWidth="lg">
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "10px" }}>
          <Paper elevation={2} sx={{ maxWidth: "300px", maxHeight: "300px", width: "100%" }}>
            <Typography variant="h1" color={dataSinAsignar?.data?.sin_asignar > 0 ? "red" : "green"} fontSize={"86px"}>
              {dataSinAsignar?.data?.sin_asignar ?? 0}
            </Typography>
            <Divider />
            <h2 style={{ padding: "5px " }}>Sucesos sin asignar</h2>
          </Paper>
          <Paper elevation={2} sx={{ maxWidth: "300px", maxHeight: "300px", width: "100%" }}>
            <Typography variant="h1" color={dataEstadoSinFinalizar?.data?.cantidad > 0 ? "red" : "green"} fontSize={"86px"}>
              {dataEstadoSinFinalizar?.data?.cantidad}
            </Typography>
            <Divider />
            <h2 style={{ padding: "5px " }}>Sucesos sin finalizar</h2>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Paper elevation={2}>
            <Doughnut data={estadosComuna} style={{ maxWidth: "300px", maxHeight: "300px" }} />
            <Divider />
            <h2>Estados por comuna</h2>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Paper elevation={2}>
            <Doughnut data={estadosEmpresa} style={{ maxWidth: "300px", maxHeight: "300px" }} />
            <Divider />
            <h2>Estados por empresas</h2>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Paper elevation={2}>
            <Doughnut data={sucesoPorIncidencias} style={{ maxWidth: "300px", maxHeight: "300px" }} />
            <Divider />
            <h2>Incidencias de la comuna</h2>
          </Paper>
        </Grid>
      </Grid>
      <br />
    </Container>
  );
};

const formatChartData = (data) => {
  return {
    labels: data.length ? data.map((item) => item.nombre) : [],
    datasets: [
      {
        data: data.length ? data.map((item) => item.value) : [],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      },
    ],
  };
};

export default Indicadores;
