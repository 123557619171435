import React from "react";
import Indicadores from "../components/organism/home";
import { Container } from "@mui/material";
import { Volver } from "../components/molecule";
import { HeaderVista } from "../components/organism";

export const HomeScreen = () => {
  return (
    <Container>
      <HeaderVista titulo={"Indicadores"} />
      <Indicadores />
    </Container>
  );
};
