import { createSlice } from "@reduxjs/toolkit";
import { Global } from "../../../constants/global";
import moment from "moment";

const initAlone = {
  agencia: "",
  domicilio: "",
  tipo: "",
  subtipo: "",
  telefono_vecino: "",
  comuna: "",
  relato: "",
  origen: "",
  estado: "",
  geolocalizacion_lat: "",
  fecha: "",
  hora: "",
  fechahora: "",
  carga_administrador: "",
  identificador: "",
  datos_empresa: {
    incidencia: "",
    estado_empresa: "",
    observaciones: "",
    fotos_empresa: "",
    fecha_ult_modif: "",
  },
  datos_comuna: {
    domicilio_real: "",
    especie_arbol: { label: "", key: "" },
    dap: "",
    altura: "",
    incidencia: "",
    calle: { label: "", key: "" },
    corte_calle: "",
    observaciones: "",
    fotos_comuna: [],
    tipoDanio: "",
    fecha_ult_modif: "",
  },
};

const actualDate = moment();
const fechaDesde = moment(actualDate).subtract(60, "days").format(Global.FormatDate).toString();
const fechaHasta = actualDate.format(Global.FormatDate).toString();

const paginationInfo = {
  hasNextPage: false,
  hasPrevPage: false,
  limit: 50,
  nextPage: 2,
  page: 1,
  pagingCounter: 1,
  prevPage: false,
  totalDocs: 218,
  totalPages: 5,
};

const filterSuceso = {
  input: "",
  fechaDesde: fechaDesde,
  fechaHasta: fechaHasta,
  horaDesde: moment().set({ hour: 9, minute: 0, second: 0 }).format(Global.FormatTime),
  horaHasta: moment().set({ hour: 17, minute: 0, second: 0 }).format(Global.FormatTime),
  tipoCortesDeCalles: [],
  incidencia: [],
  estado: [],
};

export const sucesosSlice = createSlice({
  name: "suceso",
  initialState: {
    list: [],
    alone: initAlone,
    filters: filterSuceso,
    filtersCleared: false,
    pagination: paginationInfo,
  },
  reducers: {
    alone: (state, action) => {
      state.alone = {
        ...state.alone,
        ...action.payload,
      };
    },
    aloneComuna: (state, action) => {
      state.alone = {
        ...state.alone,
        datos_comuna: {
          ...state.alone.datos_comuna,
          ...action.payload,
        },
      };
    },
    updateImagesComuna: (state, action) => {
      state.alone.datos_comuna.fotos_comuna = action.payload;
    },
    updateDatosComuna: (state, action) => {
      state.alone.datos_comuna = {
        ...state.alone.datos_comuna,
        ...action.payload,
      };
    },
    list: (state, action) => {
      state.list = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = {
        ...state.pagination,
        ...action.payload,
      };
    },
    setfilters: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },
    clean: (state) => {
      state.alone = initAlone;
      state.filters = filterSuceso;
      state.pagination = paginationInfo;
    },
    cleanFilters: (state) => {
      state.filtersCleared = true;
    },
    resetFiltersCleared: (state) => {
      state.filtersCleared = false;
    },
  },
});

export const { alone, list, clean, setfilters, resetFiltersCleared, setPagination, aloneComuna, cleanFilters, updateDatosComuna, updateImagesComuna } = sucesosSlice.actions;

export const getListSuceso = (state) => state.suceso.list;
export const getSuceso = (state) => state.suceso.alone;
export const getSucesoComuna = (state) => state.suceso.alone.datos_comuna;
export const getSucesoEmpresa = (state) => state.suceso.alone.datos_empresa;
export const getPagination = (state) => state.suceso.pagination;
export const getFilters = (state) => state.suceso?.filters || {};
export const getFiltersCleared = (state) => state.suceso.filtersCleared;
