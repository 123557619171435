import { Container } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Loading, Volver } from "../components/molecule";
import { HeaderVista, ListadoInformeEmpresa, ListadoInformeGeneral } from "../components/organism";
import ListaDeAtributosSuceso from "../components/organism/suceso/FormSuceso";
import { useGetStatesMutation } from "../store/api/endpoints/stateApiSlice";
import { useGetSucesoMutation } from "../store/api/endpoints/sucesosApiSlice";
import { useGetTipoCortesMutation } from "../store/api/endpoints/tipocortesApiSlice";
import { useGetTipoDaniosMutation } from "../store/api/endpoints/tipoDanioApiSlice";
import { useGetTipoEspecieCodigoMutation, useGetTiposEspecieArbolMutation } from "../store/api/endpoints/tipoEspecieArbolApiSlice";
import { useGetTiposIncidenteMutation } from "../store/api/endpoints/tipoIncidenteApiSlice";
import { useGetTipoNombreCalleCodigoMutation } from "../store/api/endpoints/tipoNombreCalleApiSlice";
import { useGetUsersMutation } from "../store/api/endpoints/userApiSlice";
import { setList as setListEmpresas } from "../store/slices/empresa";

import { ListEstados } from "../store/slices/estado";
import { alone, updateDatosComuna } from "../store/slices/sucesos";
import { list as listCortes } from "../store/slices/tipoCortes";
import { listDanio } from "../store/slices/tipoDanio";
import { listIncidentes } from "../store/slices/tipoIncidentes";
import { convertDateUtc } from "../utils/formatDate";

const SucesoEditScreen = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [getSucesoAlone, responseSuceso] = useGetSucesoMutation();
  const [getCortes, responseCortes] = useGetTipoCortesMutation();
  const [getEstados, responseStates] = useGetStatesMutation();
  const [getIncidencias, responseIncidencias] = useGetTiposIncidenteMutation();
  const [getDanios, responseDanios] = useGetTipoDaniosMutation();
  const [getEspecieArbol, responseEspecieArbol] = useGetTiposEspecieArbolMutation();
  const [getSingleCalle, responseSingleCalle] = useGetTipoNombreCalleCodigoMutation();
  const [getSingleEspecie, responseSingleEspecie] = useGetTipoEspecieCodigoMutation();
  const [getUsers, responseUsers] = useGetUsersMutation();

  const id = searchParams.get("id");

  useEffect(() => {
    if (responseSuceso.isSuccess) {
      let sucesoFormatted = JSON.parse(JSON.stringify(responseSuceso.data.data));
      const nuevaFecha = convertDateUtc(sucesoFormatted?.fechahora);
      const splitFecha = nuevaFecha.split(" ");
      sucesoFormatted.fecha = splitFecha[0];
      sucesoFormatted.hora = splitFecha[1];
      dispatch(alone(sucesoFormatted));
      getUsers({ page: 0, limit: 50, comunas: sucesoFormatted?.comuna });
    }
  }, [responseSuceso.isSuccess]);

  useEffect(() => {
    if (responseCortes.isSuccess) {
      dispatch(listCortes(responseCortes?.data?.data?.docs?.map((el) => ({ key: el.codigo, label: el.nombre }))));
    }
    if (responseStates.isSuccess) {
      dispatch(ListEstados(responseStates?.data?.data?.docs?.map((el) => ({ key: el.codigo, label: el.nombre, elige_empresa: el?.elige_empresa }))));
    }
    if (responseIncidencias.isSuccess) {
      dispatch(listIncidentes(responseIncidencias?.data?.data?.docs?.map((el) => ({ key: el.codigo, label: el.nombre }))));
    }
    if (responseDanios.isSuccess) {
      dispatch(listDanio(responseDanios?.data?.data?.docs?.map((el) => ({ key: el.codigo, label: el.nombre }))));
    }
    if (responseEspecieArbol.isSuccess) {
    }
    if (responseUsers.isSuccess) {
      const newUsers = responseUsers?.data?.data?.docs[0]?.empresas_asociadas?.map((el) => ({ key: el._id, label: el.nombre }));
      dispatch(setListEmpresas(newUsers));
    }
  }, [responseCortes.isSuccess, responseStates.isSuccess, responseUsers?.isSuccess, responseIncidencias.isSuccess, responseDanios.isSuccess, responseEspecieArbol.isSuccess]);

  useEffect(() => {
    if (responseSingleCalle.isSuccess) {
      const valor = responseSingleCalle?.data?.data?.nombre ?? "";
      dispatch(updateDatosComuna({ calle: valor }));
    }
    if (responseSingleEspecie.isSuccess) {
      const valor = responseSingleEspecie?.data?.data?.nombre ?? "";
      dispatch(updateDatosComuna({ especie_arbol: valor }));
    }
  }, [responseSingleCalle.isSuccess, responseSingleEspecie.isSuccess]);

  const fetchData = useCallback(async () => {
    try {
      await Promise.all([
        getCortes({ page: 0, limit: 50 }),
        getEstados({ page: 0, limit: 50 }),
        getIncidencias({ page: 0, limit: 50 }),
        getDanios({ page: 0, limit: 50 }),
        getEspecieArbol({ page: 0, limit: 50 }),
        getSucesoAlone({ id, isEmpresa: true }),
      ]);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [id, getCortes, getEstados, getIncidencias, getDanios, getEspecieArbol, getSucesoAlone]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const isGLobalLoading =
    responseSuceso.isLoading || responseCortes.isLoading || responseUsers.isLoading || responseStates.isLoading || responseIncidencias.isLoading || responseSingleEspecie.isLoading || responseSingleCalle.isLoading;

  if (isGLobalLoading) {
    return <Loading />;
  }

  return (
    <Container>
      <HeaderVista start={<Volver label="Volver" />} titulo={"Editando el suceso"} />
      <ListadoInformeGeneral />
      <ListaDeAtributosSuceso id={id} />
      <ListadoInformeEmpresa />
      <br />
    </Container>
  );
};

export default React.memo(SucesoEditScreen);
