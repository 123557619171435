import EditIcon from "@mui/icons-material/Edit";
import { Container } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { ButtonIcon } from "../components/atoms";
import { Loading, Volver } from "../components/molecule";
import { HeaderVista } from "../components/organism";
import ListInformeEmpresa from "../components/organism/suceso/ListadoInformeEmpresa";
import ListInformeGeneral from "../components/organism/suceso/ListadoInformeGeneral";
import ListInformeComuna from "../components/organism/suceso/ListInformeComuna";
import { PRIVATE_ROUTES } from "../routes/name-path.routes";
import { useGetSucesoMutation } from "../store/api/endpoints/sucesosApiSlice";
import { alone, updateDatosComuna } from "../store/slices/sucesos";
import { convertDateUtc } from "../utils/formatDate";
import moment from "moment";
/* import { useGetTipoEspecieCodigoMutation } from "../store/api/endpoints/tipoEspecieArbolApiSlice";
import { useGetTipoNombreCalleCodigoMutation } from "../store/api/endpoints/tipoNombreCalleApiSlice";
 */
const SucesoFichaScreen = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [getSuceso, responseSuceso] = useGetSucesoMutation();
  /*   const [getEspecie, responseEspecie] = useGetTipoEspecieCodigoMutation();
  const [getSingleCalle, responseSingleCalle] = useGetTipoNombreCalleCodigoMutation(); */

  const id = searchParams.get("id");

  useEffect(() => {
    if (responseSuceso.isSuccess) {
      let sucesoFormatted = { ...responseSuceso?.data?.data };
      let dateFormated = convertDateUtc(sucesoFormatted?.fechahora);
      let splitFecha = dateFormated?.split(" ");
      sucesoFormatted.fecha = moment(splitFecha[0]).format("DD-MM-YYYY");
      sucesoFormatted.hora = splitFecha[1];
      dispatch(alone(sucesoFormatted));
    }
  }, [responseSuceso.isSuccess, dispatch]);

  const fetchSuceso = useCallback(() => {
    if (id) {
      getSuceso({ id, isFicha: true });
    }
  }, [id, getSuceso]);

  useEffect(() => {
    fetchSuceso();
  }, [fetchSuceso]);

  /*   useEffect(() => {
    if (responseSingleCalle.isSuccess) {
      const valor = responseSingleCalle?.data?.data?.nombre ?? "";
      dispatch(updateDatosComuna({ calle: valor }));
    }
    if (responseEspecie.isSuccess) {
      const valor = responseEspecie?.data?.data?.nombre ?? "";
      dispatch(updateDatosComuna({ especie_arbol: valor }));
    }
  }, [responseSingleCalle.isSuccess, responseEspecie.isSuccess]); */

  if (responseSuceso.isLoading /* || responseSingleCalle.isLoading || responseEspecie.isLoading */) {
    return <Loading />;
  }

  return (
    <Container>
      <HeaderVista
        start={<Volver label="Volver" />}
        titulo={"Ficha del suceso"}
        end={<ButtonIcon variant="outlined" size="small" color="primary" startIcon={<EditIcon />} to={`${PRIVATE_ROUTES.EDIT_SUCESOS}?id=${id}`} children="Editar" />}
      />
      <ListInformeGeneral />
      <ListInformeComuna />
      <ListInformeEmpresa />
      <br />
    </Container>
  );
};

export default React.memo(SucesoFichaScreen);
